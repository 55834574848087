import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faBars,
//   faCartShopping,
//   faInfoCircle,
//   faQuestionCircle,
//   faSignIn,
//   faTimes,
// } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

import logo from "../../assets/images/logo.png";
// import otono from "../../assets/images/otono-logo.png";
// import help from "../../assets/images/help_en.png";

// import Faq from "./components/Faq";
// import Terms from "./components/Terms";
const Navigation = () => {
  // const [open, setOpen] = useState(false);
  // const [faq, setFaq] = useState(false);
  // const [terms, setTerms] = useState(false);

  const { t } = useTranslation();

  return (
    <header className="bg-twsecondary">
      <div className="flex items-center justify-between container !px-5 py-2.5">
        <div className="flex items-center gap-2">
          <a href="" className="flex w-full">
            <img className="h-8 object-contain" src={logo} alt="" />
          </a>
          <div className="text-xs whitespace-nowrap">{t("shuzenji")}</div>
        </div>
        <div className="flex items-center gap-2">
          {/* <div>
            <a
              href="https://otomotabi.otono-voice.com/usage/index_en.html"
              target="_blank"
              rel="noreferrer"
            >
              <img className="h-10 object-contain" src={help} alt="" />
            </a>
          </div> */}
          {/* <div>
            <button
              className="flex flex-col items-center"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <FontAwesomeIcon icon={faTimes} className="text-twprimary" />
              ) : (
                <FontAwesomeIcon icon={faBars} className="text-twprimary" />
              )}
              <span className="menu-text text-twprimary font-bold text-[10px] mt-1.5">
                {!open ? "MENU" : "CLOSE"}
              </span>
            </button>
          </div> */}
        </div>
      </div>
      {/* <div
        className={`absolute left-0 top-0 min-h-screen bg-white w-[80%] z-30 transition ${
          open ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="!px-5 py-2.5 bg-secondary">
          <div className="logo mt-[7px] w-[100px]">
            <img src={logo} alt="logo" />
          </div>
        </div>
        <div className="py-4 !px-5">
          <ul className="pb-3 border-b">
            <li className="flex items-center min-h-[48px] min-w-[274px]">
              <svg
                data-v-c06f2462=""
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="map-marker-smile"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 384 512"
                className="svg-inline--fa fa-map-marker-smile w-4 text-[#ff4500] mr-2.5"
              >
                <path
                  data-v-c06f2462=""
                  fill="currentColor"
                  d="M192 0C86.4 0 0 86.4 0 192c0 76.8 25.6 99.2 172.8 310.4 9.6 12.8 28.8 12.8 38.4 0C358.4 291.2 384 268.8 384 192 384 86.4 297.6 0 192 0zm64 117.16c14.82 0 26.84 12.02 26.84 26.84s-12.02 26.84-26.84 26.84-26.84-12.02-26.84-26.84 12.02-26.84 26.84-26.84zm-128 0c14.82 0 26.84 12.02 26.84 26.84s-12.02 26.84-26.84 26.84-26.84-12.02-26.84-26.84 12.02-26.84 26.84-26.84zm164.17 140.97C267.3 287.28 230.78 304 192 304c-38.75 0-75.27-16.72-100.16-45.86-5.75-6.72-4.95-16.81 1.77-22.55 6.69-5.73 16.81-4.97 22.55 1.77C134.98 259.38 162.62 272 192 272c29.41 0 57.03-12.63 75.83-34.66 5.78-6.73 15.86-7.53 22.56-1.78 6.72 5.74 7.52 15.85 1.78 22.57z"
                  className=""
                ></path>
              </svg>
              {t("trip_history")}
            </li>
            <li
              className="flex items-center min-h-[48px] min-w-[274px]"
              onClick={() => {
                setFaq(true);
              }}
            >
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className="w-4 text-[#ff4500] mr-2.5"
              />
              {t("instructions")}
            </li>
            <li className="flex items-center min-h-[48px] min-w-[274px]">
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="w-4 text-[#ff4500] mr-2.5"
              />
              <a
                href="https://otomotabi.otono-voice.com/"
                target="_blank"
                rel="noreferrer"
              >
                {t("change_area")}
              </a>
            </li>
            <li
              className="flex items-center min-h-[48px] min-w-[274px]"
              onClick={() => {
                setTerms(true);
              }}
            >
              <svg
                data-v-c06f2462=""
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="shield-check"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                className="svg-inline--fa fa-shield-check w-4 text-[#ff4500] mr-2.5"
              >
                <path
                  data-v-c06f2462=""
                  fill="currentColor"
                  d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zm-47.2 114.2l-184 184c-6.2 6.2-16.4 6.2-22.6 0l-104-104c-6.2-6.2-6.2-16.4 0-22.6l22.6-22.6c6.2-6.2 16.4-6.2 22.6 0l70.1 70.1 150.1-150.1c6.2-6.2 16.4-6.2 22.6 0l22.6 22.6c6.3 6.3 6.3 16.4 0 22.6z"
                  className=""
                ></path>
              </svg>
              {t("terms")}
            </li>
            <li>
              <a
                href="https://otono.site"
                target="_blank"
                className="flex items-center min-h-[48px] min-w-[274px]"
                rel="noreferrer"
              >
                <img
                  src={otono}
                  width={16}
                  alt="otono-logo"
                  className="mr-2.5"
                />
                {t("app_provider")}
              </a>
            </li>
            <li className="flex items-center min-h-[48px] min-w-[274px]">
              <FontAwesomeIcon
                icon={faSignIn}
                className="w-4 text-[#ff4500] mr-2.5"
              />
              <a href="https://otono-voice.com/otomotabi/members/sign_up?redirect_to=https://otomotabi.otono-voice.com/course/c-ae03521c?locale=en">
                {t("login_details")}
              </a>
            </li>
            <li className="flex items-center min-h-[48px] min-w-[274px]">
              <FontAwesomeIcon
                icon={faCartShopping}
                className="w-4 text-[#ff4500] mr-2.5"
              />
              <a href="https://book.txj.co.jp/v4/Pages/Search.aspx?exl_dn=otomotabi&exl_bs=otomotabi&exl_tg=large&exl_lng=ja-JP&exl_cur=JPY">
                {t("book_now_oto")}
              </a>
            </li>
          </ul>
        </div>
      </div> */}

      {/* <Faq faq={faq} setFaq={setFaq} />
      <Terms terms={terms} setTerms={setTerms} /> */}
    </header>
  );
};

export default Navigation;
