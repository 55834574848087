import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { i18n } from "./lang/i18n";
import { isDesktop } from "react-device-detect";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "react-responsive";

// import Footer from "./components/Footer";
import Navigation from "./components/Navigation";
import Cart from "./components/Cart";

import phone from "./assets/images/phone-2.png";
import about from "./assets/images/about-play.svg";
import otono from "./assets/images/otono-logo-full.svg";
import qr from "./assets/images/qr-2.png";
import logoDesktop from "./assets/images/logo-desktop.png";

import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { useTranslation } from "react-i18next";

function App() {
  const [language, setLanguage] = useState("jp");
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  useEffect(() => {
    const locale = searchParams.get("locale") || "jp";

    i18n.changeLanguage(locale);
    setLanguage(locale);
  }, []);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    searchParams.set("locale", lang);
    setSearchParams(searchParams);
  };

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1200px)",
  });

  if (isDesktop || isDesktopOrLaptop) {
    return (
      <div className="min-h-screen bg-twsecondary pt-[120px] px-[10%] text-[#434343]">
        <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start container max-w-[1140px]">
          <div className="lg:w-1/2 lg:px-4 mb-4 lg:mb-0 max-w-[380px] ">
            <img className="w-full h-auto" src={phone} />
          </div>
          <div className="flex flex-col items-center text-center px-4">
            <img src={logoDesktop} className="w-[40%] " alt="" />
            <h1 className="!py-5 mb-2 text-2xl font-serif font-extralight">
              {t("shuzenji_area")}
            </h1>
            <div className="text-lg font-bold leading-loose ">
              <div
                dangerouslySetInnerHTML={{ __html: t("desktop_desc") }}
              ></div>
            </div>
            <div className="py-8">
              <img src={qr} className="w-[180px] h-[180px]" alt="" />
            </div>
          </div>
        </div>
        <div className="flex py-[30px] mt-[60px]">
          <a
            href="https://otomotabi.otono-voice.com/"
            className="inline-flex justify-center mx-auto text-twprimary gap-2 font-bold border-b text-xl border-twprimary"
          >
            <img src={about} alt="" />
            {t("about_oto")}
          </a>
        </div>
        <div className="flex justify-center items-center gap-2 py-6">
          <div className="text-[#434343] text-[12px]">Produce by</div>
          <a href="https://otono.site/" target="_blank" rel="noreferrer">
            <img className="w-[95px] h-full" src={otono} alt="" />
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className={`App ${scrollTop === 0 ? "scrollTop" : ""}`}>
      <Navigation />
      <Outlet context={[changeLanguage, language]} />
      <Cart language={language} />
      {/* <div
        className="goToTop"
        onClick={() => {
          window.scrollTo(0, 0);
        }}
      >
        <FontAwesomeIcon icon={faChevronCircleUp} />
      </div> */}
      <ToastContainer />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
