import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import produce from "immer";
import { isEmpty } from "lodash";
import { Carousel } from "react-responsive-carousel";

import {
  bodyRequest,
  headers,
  quoteRequest,
  distributorQuick,
  distributorRequest,
  bodyProvider
} from "../../helpers/utils";
import { endpoints } from "../../helpers/endpoints";

import Map from "../../components/Maps";
import ProductItems from "./components/ProductItems";
import BasicInfo from "./components/BasicInfo";
import SkeletonDetail from "./components/SkeletonDetail";
import SkeletonItems from "./components/SkeletonItems";
import CheckPrice from "./components/CheckPrice";

import DefaultImg from "../../assets/images/no_image.png";

import "./style.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProductDetail = () => {
  const [service, setService] = useState();
  const [bookingQuotes, setBookingQuotes] = useState([]);
  const [detailShow, setDetailShow] = useState(false);
  const [productItemShow, setProductItemShow] = useState("none");
  const [skeletonItemShow, setSkeletonItemShow] = useState("none");
  const [quotesInfo, setQuotesInfo] = useState({});
  const [errorItems, setErrorItems] = useState(false);
  const [productId, setProductId] = useState();
  const [totalPrice, setTotalPrice] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [imageSelected, setImageSelected] = useState(0);
  const [providerName, setProviderName] = useState();
  const [pax, setPax] = useState(1);
  const [childPax, setChildPax] = useState(0);
  const [senior, setSenior] = useState(0);


  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const language = searchParams.get("locale");
  const name = searchParams.get("id");
  const { t } = useTranslation();

  const today = new Date();
  const date = new Date(today);
  date.setDate(date.getDate() + 1);

  const detailRequest = bodyRequest;
  const onReq = searchParams.get("onRequest");

  detailRequest.request.Filter = {
    Type: "Service",
    Ids: "",
  };

  if (state?.id) {
    detailRequest.request.Filter.Ids = [state?.id];
  } else {
    delete detailRequest.request.Filter.Ids;
    detailRequest.request.Filter.Names = [name];
  }

  const getProviderName = (service) => {
    axios.post(endpoints.search, bodyProvider).then((res) => {
      res.data.Entities.forEach(element => {
        element.Children.forEach(child => {
          if (child.Id === service.Id) {
            setProviderName(element.Name)
          }
        });
      });
    })
  }

  useEffect(() => {
    const body = document.querySelector("header");

    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );

    const langParams = searchParams.get("locale");
    detailRequest.request.Language = `${language === "en" ? "en-US" : "ja-JP"}`;
    quoteRequest.request.Language = `${language === "en" ? "en-US" : "ja-JP"}`;
    bodyProvider.request.Language = `${language === "en" ? "en-US" : "ja-JP"}`;

    if (langParams) {
      detailRequest.request.Language = `${langParams === "en" ? "en-US" : "ja-JP"
        }`;
      quoteRequest.request.Language = `${langParams === "en" ? "en-US" : "ja-JP"
        }`;
      bodyProvider.request.Language = `${langParams === "en" ? "en-US" : "ja-JP"}`;
    }

    setDetailShow(false);
    setProductItemShow("none");

    detailRequest.request.Output.Availability.NumberOfDays = 31;

    detailRequest.request.Output.Children = {
      Output: {
        CommonContent: {
          All: true,
        },
        Features: true,
        Rating: true,
        Reviews: {
          IncludeFullDescription: true,
          IncludeShortReview: true,
          MaxReturnCount: 10,
          MaxReturnCountSpecified: true,
        },
        Availability: {
          StartDate: new Date(),
          NumberOfDays: 4,
          MergeMethod: 2,
          FlagCampaign: true,
        },
      },
      Filter: {
        Ids: null,
        Type: 4,
      },
    };
    const product_id = searchParams.get("product_id");

    setProductId(product_id);
    detailRequest.request.ShortName = onReq
      ? distributorRequest
      : distributorQuick;

    if (!langParams || language === langParams) {
      axios
        .post(endpoints.search, detailRequest, { headers: headers })
        .then((response) => {
          setService(response.data.Entities[0]);
          getProviderName(response.data.Entities[0]);
          const el = document.querySelector("meta[name='description']");
          el.setAttribute(
            "content",
            response.data.Entities[0].ShortDescription
          );
          setDetailShow(true);
        });
    }
  }, [searchParams, location, language]);

  useEffect(() => {
    setBookingQuotes([]);
  }, []);

  useEffect(() => {
    if (service && service.IndustryCategoryGroups[0] === 3) {
      getQuote();
    }
  }, [service]);

  useEffect(() => {
    setBookingQuotes(bookingQuotes.sort((a, b) => a.Name - b.Name));
  }, [bookingQuotes]);

  const getQuote = (values) => {
    setProductItemShow("none");

    quoteRequest.request.Configurations[0].Pax.Adults =
      parseInt(values && values.pax) || 1;
    quoteRequest.request.Configurations[0].Pax.Children =
      parseInt(values && values.children) || 0;
    quoteRequest.request.Configurations[0].Pax.Seniors =
      parseInt(values && values.seniors) || 0;
    quoteRequest.request.CommencementDate =
      (values && new Date(values.date)) || new Date();
    quoteRequest.request.Duration = parseInt(values && values.duration) || null;

    setBookingQuotes([]);

    if (service && service.Children.length > 0) {
      setBookingQuotes([]);
      if (onReq) quoteRequest.request.Shortname = distributorRequest;
      else quoteRequest.request.Shortname = distributorQuick;

      if (productId) {
        let indexId = 0;
        let selectedId = {};
        service.Children.forEach((children, i) => {
          if (children.Code === productId) {
            indexId = i;
            selectedId = children;

            dispatchQuoteBooking(selectedId, indexId);
          }
        });
      } else {
        service.Children.map((children, i) => {
          dispatchQuoteBooking(children, i);
        });
      }
    }
  };

  const dispatchQuoteBooking = (children, i) => {
    quoteRequest.request.IndustryCategoryGroup =
      children.IndustryCategoryGroups[0];
    quoteRequest.request.IndustryCategory = children.IndustryCategory;
    quoteRequest.request.Configurations[0].ProductId = children.Id;
    setSkeletonItemShow("block");

    axios
      .post(endpoints.bookingQuote, quoteRequest, { headers: headers })
      .then((response) => {
        if (response.data.Configurations[0].Quotes) {
          const mergeData = { ...service.Children[i], ...response.data };
          mergeData.id = response.data.Configurations[0].ProductId;
          mergeData.quantity = 1;
          mergeData.type = onReq === "true" ? "req" : "quick";
          mergeData.totalPrice = response.data.Configurations[0].Quotes
            ? response.data.Configurations[0].Quotes[0].TotalPrice
            : null;
          mergeData.price = response.data.Configurations[0].Quotes
            ? response.data.Configurations[0].Quotes[0].TotalPrice
            : null;
          setBookingQuotes((data) => [...data, mergeData]);
        }

        setProductItemShow("block");
        setSkeletonItemShow("none");
      })
      .catch((error) => {
        console.log(error);
        setErrorItems(true);
        setProductItemShow("block");
        setSkeletonItemShow("none");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let values = null;
    if (service.IndustryCategoryGroups[0] !== 3) {
      values = {
        date: e.target[0] ? e.target[0].value : "",
        duration:
          service.IndustryCategoryGroups[0] === 0 ? e.target[1].value : null,
        pax: e.target.pax.value,
        children: service.Settings.PresentChildren
          ? e.target.children.value
          : "",
        seniors: service.Settings.PresentSeniors ? e.target.senior.value : "",
      };
      setQuotesInfo(values);
    }

    getQuote(values);
  };

  const changeQuantity = (value, id) => {
    bookingQuotes.map((item) => {
      if (item.id === id) {
        item.quantity = value;
        const price = value * item.Configurations[0].Quotes[0].TotalPrice;
        if (!isEmpty(totalPrice)) {
          totalPrice.map((item) => {
            if (item.id === id) {
              setTotalPrice(
                produce((draft) => {
                  const dataPrice = draft.find((item) => item.id === id);
                  dataPrice.totalPrice = price;
                })
              );
            } else {
              setTotalPrice((prev) => [...prev, { id: id, totalPrice: price }]);
            }
          });
        } else {
          setTotalPrice((prev) => [...prev, { id: id, totalPrice: price }]);
        }
      }
    });
  };


  const getServiceType = () => {
    let serviceType = null;
    if (service && service.IndustryCategoryGroups) {
      switch (service.IndustryCategoryGroups[0]) {
        case 0:
          serviceType = t("accommodation");
          break;
        case 1:
          serviceType = t("activity");
          break;
        case 2:
          serviceType = t("restaurant");
          break;
        case 3:
          serviceType = t("produce");
          break;
        default:
          return t("accommodation");
      }
    }

    return serviceType;
  };

  const calendarUpdate = (date) => {
    detailRequest.request.Output.Availability.StartDate = date;

    axios
      .post(endpoints.search, detailRequest, { headers: headers })
      .then((response) => {
        setService(response.data.Entities[0]);
        setDetailShow(true);
      });
  };

  const changePax = (count, type) => {
    debugger; //eslint-disable-line
    if (count) {
      let values = {
        date: quotesInfo.date || new Date(),
        duration: quotesInfo.duration,
        pax: quotesInfo.pax || 1,
        children: quotesInfo.children,
        seniors: quotesInfo.seniors,
      };

      if (type === "pax") {
        values.pax = count;
        setPax(count);
      } else if (type === "children") {
        values.children = count;
        setChildPax(count);
      } else {
        values.seniors = count;
        setSenior(count);
      }

      setQuotesInfo(values);
      getQuote(values);
    }
  };

  return (
    <div className="productDetail">
      <div className="container">
        <p id="breadcrumbs">
          <a href="https://otomotabi.otono-voice.com/">HOME</a>
          <span><FontAwesomeIcon icon={faChevronRight} /></span>
          <a href={`/?category=${service?.IndustryCategoryGroups[0]}`}>{getServiceType()}</a>
          <span><FontAwesomeIcon icon={faChevronRight} /></span>
          <span>{service?.Name}</span>
        </p>
        <div className="product">
          <div
            className="skeletonWrapper"
            style={{ display: !detailShow ? "block" : "none" }}
          >
            <SkeletonDetail />
          </div>
          {service && (
            <div
              className="productWrapper"
              style={{ display: detailShow ? "block" : "none" }}
            >

              <h2 className="title">{service.Name}</h2>
              <div className="productHeader">

                <div className="carousel">
                  {service.Images !== null ? (
                    <>
                      <Carousel
                        showArrows={true}
                        dynamicHeight={false}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        showStatus={false}
                        onClickItem={() => setOpenModal(true)}
                        onChange={(i) => setImageSelected(i)}
                      >
                        {service.Images.map((image) => {
                          return (
                            <div key={image.Id}>
                              <img src={image.Url} />
                            </div>
                          );
                        })}
                      </Carousel>
                      <Modal
                        show={openModal}
                        onHide={() => setOpenModal(false)}
                        centered
                        size="lg"
                      >
                        <Carousel
                          showArrows={true}
                          dynamicHeight={true}
                          preventMovementUntilSwipeScrollTolerance={true}
                          swipeScrollTolerance={50}
                          showStatus={false}
                          selectedItem={imageSelected}
                        >
                          {service.Images.map((image) => {
                            return (
                              <div key={image.Id}>
                                <img src={image.Url} />
                              </div>
                            );
                          })}
                        </Carousel>
                      </Modal>
                    </>
                  ) : (
                    <img src={DefaultImg} />
                  )}
                </div>
              </div>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: service.LongDescription }}
              ></div>
              {service?.IndustryCategoryGroups[0] !== 3 && (
                <CheckPrice
                  date={date}
                  service={service}
                  handleSubmit={handleSubmit}
                  calendarUpdate={calendarUpdate}
                  pax={pax}
                  childPax={childPax}
                  senior={senior}
                  setPax={setPax}
                  setChildPax={setChildPax}
                  setSenior={setSenior}
                />
              )}
              <div
                className="availableProducts mb-4"
                style={{ display: productItemShow }}
              >
                <div className="sectionTitle">
                  <span>
                    {service?.IndustryCategoryGroups &&
                      service.IndustryCategoryGroups[0] === 1
                      ? t("available_products_activity")
                      : service.IndustryCategoryGroups[0] === 3
                        ? t("available_products_goods")
                        : t("available_products")}
                  </span>
                </div>
                <ProductItems
                  bookingQuotes={bookingQuotes}
                  changeQuantity={changeQuantity}
                  language={language}
                  service={service}
                  quotesInfo={quotesInfo}
                  error={errorItems}
                  totalPrice={totalPrice}
                  onRequest={onReq}
                  changePax={changePax}
                />
              </div>
              <SkeletonItems skeletonItemShow={skeletonItemShow} />
              <div className="info mb-4">
                <div className="infoTitle">
                  <h3>
                    <i>
                      <svg viewBox="0 0 19.67 19.67">
                        <path d="M9.68,6.56a1,1,0,1,0-1-1A1,1,0,0,0,9.68,6.56Zm1.58,8h-.87V8.79a.5.5,0,0,0-.5-.5H8.4a.5.5,0,1,0,0,1h1v5.23h-1a.51.51,0,0,0-.5.5.5.5,0,0,0,.5.5h2.86a.5.5,0,0,0,.5-.5A.5.5,0,0,0,11.26,14.52ZM9.83,0a9.84,9.84,0,1,0,9.84,9.83A9.84,9.84,0,0,0,9.83,0Zm0,18.67a8.84,8.84,0,1,1,8.84-8.84A8.85,8.85,0,0,1,9.83,18.67Z"></path>
                      </svg>
                    </i>
                    {t("basic_info")}
                  </h3>
                </div>
                <BasicInfo service={service} providerName={providerName} />
              </div>
            </div>
          )}
        </div>
      </div>
      {service && service.Geocodes !== null && (
        <div className="map">
          <div className="mapContainer">
            <Map positions={service} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductDetail;
