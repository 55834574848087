import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DefaultImg from "../../../../assets/images/no_image.png";
import { formatMoney } from "../../../../helpers/formatters";
import { useNavigate } from "react-router-dom";

import './style.scss'

const propTypes = {
  services: PropTypes.array,
  loadMore: PropTypes.func,
  totalPage: PropTypes.number,
  currentPage: PropTypes.number,
  state: PropTypes.string,
  category: PropTypes.number,
  stateButton: PropTypes.string,
};

const Items = ({
  services,
  loadMore,
  totalPage,
  state,
  currentPage,
  stateButton,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [lang, setLang] = useState("en");

  const goToDetail = (id, name) => {
    navigate(
      `/product?id=${name}&lang=${lang}${stateButton === "request" ? "&onRequest=true" : ""
      }`,
      { state: { id: id } }
    );
  };

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  return (
    <>
      <section>
        {services && services.length > 0 ? (
          services.map((service) => {
            return (
              <div
                className="item shopping"
                key={service.Id}
              >
                <div className="image"
                  onClick={(e) => {
                    e.preventDefault();
                    goToDetail(service.Id, service.Name);
                  }}>
                  <img
                    src={
                      service.Images !== null
                        ? service.Images[0].Url
                        : DefaultImg
                    }
                    alt={service.Name}
                  />
                </div>

                <div className="info">
                  <div className="titleShop"
                    onClick={(e) => {
                      e.preventDefault();
                      goToDetail(service.Id, service.Name);
                    }}
                  >
                    {service.Name}</div>
                  {service.Availability.Calendar.LowestRate &&
                    <div className="price">
                      {(lang === "jp"
                        ? `¥${formatMoney(
                          service.Availability.Calendar.LowestRate
                        )} から`
                        : `From ¥${formatMoney(
                          service.Availability.Calendar.LowestRate
                        )}`)}
                    </div>}
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={{
                      __html: service.LongDescription,
                    }}
                  ></div>
                </div>
                <div></div>
                <div className="buttonWrapper">
                  <button
                    className="btn btn-primary"
                    onClick={() => goToDetail(service.Id, service.Name)}
                  >
                    {t("view_details")}
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <h3 className="text-center">{t("not_found")}</h3>
        )}
      </section>
      <div className="loadMore mb-3">
        {totalPage > 1 && totalPage > currentPage && state === "quick" && (
          <button
            className="py-2 btn btn-secondary"
            onClick={() => loadMore()}
          >
            {t("load_more")}
          </button>
        )}
      </div>
    </>
  );
};

Items.propTypes = propTypes;

export default Items;
