import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

Breadcrumb.propTypes = {
  breadcrumbName: PropTypes.string,
  category: PropTypes.string,
};

function Breadcrumb({ category, breadcrumbName }) {
  const { t } = useTranslation();
  return (
    <div className="container">
      <p id="breadcrumbs">
        <a href="https://otomotabi.otono-voice.com/course/c-ae03521c">HOME</a>
        <span><FontAwesomeIcon icon={faChevronRight} /></span>
        <a href="/">{t('search')}</a>
        {category || category === 0 ? (
          <>
            <span><FontAwesomeIcon icon={faChevronRight} /></span>
            <a href={`?category=${category}`}>{breadcrumbName}</a>
          </>
        ) : null}
      </p>
    </div>
  );
}

export default Breadcrumb;
