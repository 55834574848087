import React, { useEffect, useState } from "react";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import { useOutletContext, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isEmpty, toLower } from "lodash";
import moment from "moment";

import {
  bodyRequest,
  distributorQuick,
  distributorRequest,
} from "../../helpers/utils";
import { endpoints } from "../../helpers/endpoints";

import SkeletonProducts from "./components/SkeletonProducts";
import Filter from "./components/Filter";
import Map from "../../components/Maps";
import Items from "./components/Items";
import Breadcrumb from "./components/Breadcrumb";


import "react-loading-skeleton/dist/skeleton.css";
import "./style.scss";

const Products = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [changeLanguage, language] = useOutletContext();

  const options = [
    {
      value: "Rate-Ascending",
      label: t("rate_ascending"),
    },
    {
      value: "Rate-Descending",
      label: t("rate_descending"),
    },
    {
      value: "Name-Ascending",
      label: t("name_ascending"),
    },
    {
      value: "Name-Descending",
      label: t("name_descending"),
    },
  ];

  const [stateMap, setStateMap] = useState([]);
  const [services, setServices] = useState([]);
  const [geocodes, setGeocodes] = useState(false);

  const [skeletonShow, setSkeletonShow] = useState("none");
  const [productsShow, setProductsShow] = useState("block");
  const [stateButton, setStateButton] = useState("quick");

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageCount, setPageCount] = useState();

  const [selectedOption, setSelectedOption] = useState("");

  const [category, setCategory] = useState();
  const [date, setDate] = useState("");
  const [priceRange, setPriceRange] = useState();
  const [keyword, setKeyword] = useState();
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [token, setToken] = useState();

  const [breadcrumbName, setBreadcrumbName] = useState("体験");

  const productsRequest = bodyRequest;
  const langParams = searchParams.get("locale");

  useEffect(() => {
    searchParams.delete("pages");
    setSearchParams(searchParams);

    setPage(1);
    setToken(null);
    productsRequest.request.Paging.PageNumber = 1;

    productsRequest.request.Availability = {
      MergeMethod: 1,
      Window: {
        Size: 42,
        StartDate: new Date(),
      },
    };
    productsRequest.request.Filter = {
      TagCritera: {},
      Type: "Service",
    };

    const category = searchParams.get("category");
    const min = searchParams.get("min");
    const max = searchParams.get("max");
    const keyword = searchParams.get("keyword");
    const date = searchParams.get("date");
    const sort = searchParams.get("sort");
    const city = searchParams.get("city");
    const type = searchParams.get("type");

    if (category) {
      productsRequest.request.Filter.TagCriteria = {
        IndustryCategoryGroups: [category],
      };
      setCategory(parseInt(category));
    } else {
      productsRequest.request.Filter.TagCriteria = {
        IndustryCategoryGroups: [0, 1, 2],
      };
    }

    if (min || max) {
      productsRequest.request.Filter.Bookability.RateRange = {
        Min: min,
        Max: max,
      };
      setPriceRange(`${min}-${max}`);
    }

    if (date) {
      productsRequest.request.Availability.Window.StartDate = new Date(date);
      setDate(new Date(date));
    }

    if (keyword) {
      productsRequest.request.Filter.Names = [`%${keyword}%`];
      setKeyword(keyword);
    }

    if (selectedKeyword) {
      productsRequest.request.Filter.Names = [`%${selectedKeyword}%`];
    }

    if (sort) {
      setSelectedOption(sort);
      productsRequest.request.Sorting = [
        {
          By: `${sort.split("-")[0]}`,
          Direction: `${sort.split("-")[1]}`,
        },
      ];
    } else {
      productsRequest.request.Sorting = [
        {
          By: "Random",
        },
      ];
    }

    if (city) setSelectedCity((data) => [...data, city]);

    delete productsRequest.request.Filter.Ids;

    productsRequest.request.Language = `${language === "en" ? "en-US" : "ja-JP"
      }`;
    pageNames();
    getData({ checkCity: city, type: type || "quick" });
  }, []);

  useEffect(() => {
    productsRequest.request.Language = `${language === "en" ? "en-US" : "ja-JP"
      }`;

    if (langParams === language) {
      pageNames();
      getData();
      window.scrollTo(0, 0);
    }
  }, [language]);

  const resetPage = () => {
    setPage(1);
    searchParams.delete("pages");
    setToken(null);
  };

  useEffect(() => {
    stateMap &&
      stateMap.map((item) => {
        if (item.HasGeocodes) {
          setGeocodes(true);
        }
      });
  }, [stateMap]);

  const GEOCODE_URL =
    "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";

  // Dispatch data
  const dispatchData = (page, checkCity, type) => {
    productsRequest.request.ShortName =
      type === "request" ? distributorRequest : distributorQuick;
    productsRequest.request.Paging.PageNumber = page || 1;
    productsRequest.request.Paging.PageSize = 12;

    axios.post(endpoints.search, productsRequest).then((response) => {
      if (!isEmpty(checkCity)) {
        setServices([]);
        let dataServices = [];
        checkCity.forEach((city) => {
          response.data.Entities.forEach((item) => {
            const coor = !isEmpty(item.Geocodes)
              ? item.Geocodes[0].Geocode
              : null;

            if (coor) {
              axios
                .get(GEOCODE_URL + `${coor.Longitude},${coor.Latitude}`)
                .then((res) => {
                  if (
                    res &&
                    toLower(res.data.address.Address).includes(toLower(city))
                  ) {
                    setServices((data) => [...data, item]);
                    dataServices = [...dataServices, item];
                  }
                });
            }
          });
        })
        setPageCount(dataServices.length);
      } else {
        if (page && page > 1) {
          setServices((data) => [...data, ...response.data.Entities]);
        } else {
          setServices(response.data.Entities);
          setTotalPage(response.data.Paging.NumberOfPages);
          setPageCount(response.data.Paging.NumberOfResults);
          setToken(response.data.Paging.Token);
        }
      }
      const sort = searchParams.get("sort");
      if (sort && sort === 'Rate-Ascending') {
        response.data.Entities.sort((a, b) => {
          return a.Availability?.Calendar?.LowestRate - b.Availability?.Calendar?.LowestRate
        })
      } else if (sort && sort === 'Rate-Descending') {
        response.data.Entities.sort((a, b) => {
          return b.Availability?.Calendar?.LowestRate - a.Availability?.Calendar?.LowestRate
        })
      }
      setProductsShow("block");
      setSkeletonShow("none");
    });
  };

  /* eslint-disable */
  const updateStateMap = (data) => {
    setStateMap((ss) => [...ss, ...data]);
  };
  const dispatchMap = (type) => {
    productsRequest.request.Paging = {};
    productsRequest.request.ShortName =
      type === "request" ? distributorRequest : distributorQuick;

    axios.post(endpoints.search, productsRequest).then((response) => {
      updateStateMap(response.data.Entities);
    });
  };

  const getData = (payload) => {
    window.scrollTo(0, 0);
    setSkeletonShow("block");
    if (payload?.page && payload?.page > 1) {
      productsRequest.request.Paging.PageNumber = payload?.page;
      searchParams.get("page", page);
      productsRequest.request.Paging.Token = token;
      delete productsRequest.request.Filter;
      delete productsRequest.request.Campaign;
      delete productsRequest.request.Availability;

      dispatchData(payload?.page, payload?.checkCity, payload?.type);
    } else {
      setProductsShow("none");
      productsRequest.request.Paging.PageNumber = 1;

      dispatchData(null, payload?.checkCity, payload?.type);
    }

    dispatchMap("quick");
    dispatchMap("request");
  };

  // Filter
  const filterData = (values) => {
    resetPage();
    productsRequest.request.Filter = {
      Type: "Service",
      TagCriteria: {},
    };
    if (values.minRange) {
      if (values.minRange === "0") {
        productsRequest.request.Filter.Bookability.RateRange = {};
        searchParams.delete("min");
        searchParams.delete("max");
      } else {
        productsRequest.request.Filter.Bookability.RateRange = {
          Min: values.minRange,
          Max: values.maxRange,
        };
        searchParams.set("min", values.minRange);
        searchParams.set("max", values.maxRange);
      }
    }
    if (values.date) {
      productsRequest.request.Availability.Window.StartDate = new Date(
        values.date
      );
      if (moment(values.date).format("LL") !== moment().format("LL")) {
        searchParams.set(
          "date",
          moment(new Date(values.date)).format("YYYY-MM-DD")
        );
      } else {
        searchParams.delete("date");
      }
    } else {
      searchParams.delete("date");
    }

    if (!values.category || values.category === "all") {
      productsRequest.request.Filter.TagCriteria = {
        IndustryCategoryGroups: [0, 1, 2],
      };
      searchParams.delete("category");
    } else {
      productsRequest.request.Filter.TagCriteria = {
        IndustryCategoryGroups: [values.category],
      };
      searchParams.set("category", values.category);
    }
    const dataKeyword = selectedKeyword.map((item) => `%${item}%`);

    if (values.keyword) {
      dataKeyword.push(`%${values.keyword}%`);
      productsRequest.request.Filter.Names = dataKeyword;
      searchParams.set("keyword", values.keyword);
    } else {
      if (!isEmpty(values.selectedKeyword)) {
        delete productsRequest.request.Filter.Names;
      }
      searchParams.delete("keyword");
    }

    if (!isEmpty(values.selectedKeyword)) {
      productsRequest.request.Filter.Names = dataKeyword;
      searchParams.set("selectedKeyword", values.selectedKeyword);
    } else {
      if (!values.keyword) {
        delete productsRequest.request.Filter.Names;
      }
      searchParams.delete("selectedKeyword");
    }

    if (!isEmpty(values.city)) {
      searchParams.set("city", values.city);
    } else {
      searchParams.delete("city");
    }

    if (page > 1) {
      setPage(1);
      setTotalPage(1);
    }

    setSearchParams(searchParams);
    pageNames();

    getData({ checkCity: values.city });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  };

  const changeToQuick = () => {
    getData({ page: 1, type: "quick" });
    setStateButton("quick");
    searchParams.set("type", "quick");
  };

  const changeToRequest = () => {
    getData({ page: 1, type: "request" });
    setStateButton("request");
    searchParams.set("type", "request");
  };

  const changeToMap = () => {
    setStateButton("map");
  };

  // Sort
  const onSort = (value) => {
    resetPage();
    setSelectedOption(value);
    productsRequest.request.Sorting = [
      {
        By: `${value.split("-")[0]}`,
        Direction: `${value.split("-")[1]}`,
        PositionOfNull: "AlwaysOnBottom"
      },
    ];
    searchParams.set("sort", value);
    setSearchParams(searchParams);
    getData();
  };

  // Load more data
  const loadMore = () => {
    const paging = page + 1;
    searchParams.set("pages", paging);
    searchParams.delete("pages_request");
    setSearchParams(searchParams);

    setPage(paging);
    getData({ page: paging, type: stateButton });
  };

  const pageNames = () => {
    let breadcrumb = "";
    let title = "";
    const category = searchParams.get("category");
    switch (category) {
      case "0":
        breadcrumb = t("accommodation");
        break;
      case "1":
        breadcrumb = t("activity");
        break;
      case "2":
        breadcrumb = t("restaurant");
        break;
      case "3":
        breadcrumb = t("shopping");
        break;
      default:
        breadcrumb = t("search");
        break;
    }

    setBreadcrumbName(breadcrumb);
  };

  return (
    <div className="products">
      <div className="productsWrapper" style={{ display: productsShow }}>
        <Breadcrumb category={category} breadcrumbName={breadcrumbName} />
        <Filter
          lang={language}
          filter={filterData}
          date={date}
          category={category}
          priceRange={priceRange}
          keyword={keyword}
          setKeyword={setKeyword}
          setDate={setDate}
          setCategory={setCategory}
          setPriceRange={setPriceRange}
          selectedKeyword={selectedKeyword}
          setSelectedKeyword={setSelectedKeyword}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
        />

        <div className="container categoryTabs">
          <nav >
            <a href="?category=0" >
              <div className={`categoryTabs__item ${category == "0" ? "active" : ""}`}>
                {t('accommodation')}
              </div>
            </a>
            <a href="?category=1" >
              <div className={`categoryTabs__item ${category == "1" ? "active" : ""}`}>
                {t('activity')}
              </div>
            </a>
            <a href="?category=2" >
              <div className={`categoryTabs__item ${category == "2" ? "active" : ""}`}>
                {t('restaurant')}
              </div>
            </a>
            <a href="?category=3" >
              <div className={`categoryTabs__item ${category == "3" ? "active" : ""}`}>
                {t('shopping')}
              </div>
            </a>
          </nav>
        </div>
        <div className="productsOption">
          <div className="container">
            <div className="searchCount mb-3">
              <span>{pageCount}</span>
              <span className="count">件</span> ありました
            </div>
            <div className="optionWrapper">
              <div className="d-flex sort">
                {options.map((o, i) => (
                  <Button
                    variant={selectedOption === o.value ? "primary" : "white"}
                    key={i}
                    onClick={() => onSort(o.value)}
                  >
                    {o.label}
                  </Button>
                ))}
              </div>
              <div className="d-flex">
                <div className="me-2">
                  <Form.Select
                    onChange={(e) => changeLanguage(e.target.value)}
                    value={langParams ? langParams : language}
                  >
                    <option value="en">English</option>
                    <option value="jp">日本語</option>
                  </Form.Select>
                </div>
                <div className="Products__buttonType">
                  <Button
                    variant={stateButton === "quick" ? "primary" : "white"}
                    onClick={() => changeToQuick()}
                  >
                    {t("quick_booking")}
                  </Button>
                  {/* <Button
                    variant={stateButton === "request" ? "primary" : "white"}
                    onClick={() => changeToRequest()}
                  >
                    {t("request_book")}
                  </Button> */}
                  <Button
                    variant={stateButton === "map" ? "primary" : "white"}
                    onClick={() => changeToMap()}
                  >
                    {t("map")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="productItems"
            style={{ display: stateButton !== "map" ? "block" : "none" }}
          >
            <Items
              services={services}
              loadMore={loadMore}
              totalPage={totalPage}
              currentPage={page}
              state={stateButton}
              category={category}
              stateButton={stateButton}
            />
          </div>
          <div
            className="productsMap"
            style={{ display: stateButton === "map" ? "block" : "none" }}
          >
            {geocodes && stateMap.length > 0 && (
              <Map positions={stateMap} zoom={9} />
            )}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="skeletonWrapper" style={{ display: skeletonShow }}>
          <SkeletonProducts currentPage={page} category={category} />
        </div>
      </div>
    </div>
  );
};

export default Products;
