import React, { useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Calendar from "react-calendar";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import "react-calendar/dist/Calendar.css";
import "./style.scss";

const propTypes = {
  filter: PropTypes.func,
  setDate: PropTypes.func,
  category: PropTypes.number,
  date: PropTypes.string,
  keyword: PropTypes.string,
  setKeyword: PropTypes.func,
  setSelectedKeyword: PropTypes.func,
  selectedKeyword: PropTypes.array,
  setSelectedCity: PropTypes.func,
  selectedCity: PropTypes.array,
};

const Filter = ({
  filter,
  category,
  keyword,
  setKeyword,
  setDate,
  date,
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const categoryParams = searchParams.get("type");


  const [showCalendar, setShowCalendar] = useState(false);

  const onChange = (date) => {
    setDate(date);
    setShowCalendar(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const dateData = categoryParams === "3" ? false : event.target[0].value;
    const data = {
      date: dateData ? new Date(event.target[0].value) : "",
      category: category,
      keyword: keyword,
    };
    setShowCalendar(false);
    filter(data);
  };

  const minDate = () => {
    const someDate = new Date();
    const result = someDate.setDate(someDate.getDate() + 0);
    return new Date(result);
  };
  return (
    <div className="filterWrapper">
      <div className="container">
        <div className="searchTitle">{t("search_filter")}</div>
        <form onSubmit={handleSubmit} className="mt-3">
          <Row className="position-relative">
            {category !== 3 && (
              <Col xs={12} className="col-lg mb-3 mb-lg-0">
                <Form.Control
                  type="input"
                  name="date"
                  placeholder={t("date_placeholder")}
                  readOnly
                  value={date && moment(date).format("YYYY-MM-DD")}
                  onClick={() => {
                    setShowCalendar(!showCalendar);
                  }}
                />
                <Calendar
                  minDate={minDate()}
                  onChange={(date) => onChange(date)}
                  value={date}
                  className={!showCalendar ? "hide" : ""}
                />
              </Col>
            )}
            <Col xs={12} className="col-lg mb-3 mb-lg-0 keyword-button">
              <div className="w-100">
                <Form.Control
                  type="text"
                  name="keyword"
                  onClick={() => {
                    setShowCalendar(false);
                  }}
                  className="keywordType"
                  onChange={(e) => setKeyword(e.target.value)}
                  placeholder="キーワードを入力"
                  value={keyword}
                />
              </div>
              <div className="btnWrapper">
                <Button
                  className="searchButton"
                  variant="primary"
                  type="submit"
                >
                  <FontAwesomeIcon icon={faSearch} />
                </Button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
    </div>
  );
};

Filter.propTypes = propTypes;

export default Filter;
