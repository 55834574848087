export const TRANSLATIONS_EN = {
  search: "Search",
  search_filter: "Search",
  search_page: "Search",
  all_breadcrumb: "All",
  booking: "Booking",
  not_found:
    "Sorry, there are no products to display. Please revisit this page again soon. 😉",
  all_categories: "All Categories",
  all_categories_shop: "All Categories",
  accommodation: "Accommodation",
  activity: "Activity",
  restaurant: "Restaurant",
  produce: "Produce",
  search_keyword: "Type Keywords",
  view_details: "View",
  basic_info: "Basic Information",
  map: "Map",
  details: "Details",
  home: "Home",
  list: "List",
  name: "Name",
  address: "Address",
  phone: "Phone",
  website: "Website",
  email: "Email",
  fax: "Fax",
  from: "From",
  price: "Price",
  date: "Date",
  rate: "Rate",
  price_range: "Price Range",
  keyword: "Type Keyword",
  available_products: "Available Products",
  available_products_activity: "Available Products",
  available_products_goods: "Available Products",
  book_now: "Book Now",
  book_now_activ: "Book Now",
  book_now_goods: "Book Now",
  check_price: "Check Price & Availability",
  check_price_activ: "Check Price & Availability",
  check_price_goods: "Check Price & Availability",
  cart_empty: "Cart is Empty",
  first_name: "First Name",
  last_name: "Last Name",
  re_email: "Retype Email Address",
  mobile: "Mobile/Cell phone",
  city: "City",
  state: "Prefecture",
  country: "Country",
  zip: "Zip/Postal Code",
  type_keywords: "Type Keywords",
  product: "Product",
  options: "Options/Cost",
  totals: "Totals",
  special_requests: "Special Requests",
  quick_booking: "Instant Booking",
  request_book: "Pay on Site",
  customer_detail: "Customer Detail",
  load_more: "Load More",
  request_to_book: "Pay on Site",
  start_time: "Start time: {{ time }}",
  continue_payment: "Continue to Payment",
  close: "Close",
  quantity: "Quantity",
  see_more: "See More",
  modal_header: "Make a booking request",
  modal_desc:
    "Please note, this booking needs to be confirmed with the operator.<br><br>You'll receive an email within 24 hours to confirm if your booking has been accepted and if so you can pay securely online",
  confirm: "Confirm",
  submit: "Submit",
  items: "Item(s)",
  go_back: "Go Back",
  booking_req_detail_wait: "Booking Request Details - awaiting confirmation",
  booking_req_instructions:
    "You'll receive an email within 24 hours to confirm if your booking request has been accepted by the operator. Payment is not due until your booking request has been accepted.",
  request_date: "Request Date (UTC)",
  booking_reference: "Booking Reference",
  booked_by: "Booked by",
  supplier_information: "Supplier Information",
  total_gst: "Total (inc. GST)",
  amount_paid: "Amount Paid (inc. GST)",
  amount_owing: "Amount Owing (inc. GST)",
  adult: "Adult(s)",
  check_in: "Check In",
  check_out: "Check Out",
  not_found_page: "Not Found",
  booking_date: "Booking Date",
  back_to_booking: "Back to Booking Page",
  sort_by: "Sort by",
  name_ascending: "Name-Ascending",
  name_descending: "Name-Descending",
  rate_ascending: "Rate-Ascending",
  rate_descending: "Rate-Descending",
  not_available: "Not Available",
  not_available_activ: "Not Available",
  not_available_goods: "Not Available",
  price_tax: "Price (tax incl.)",
  date_placeholder: "dd-mm-yy",
  shopping: "Shopping",
  item_added: "Item added to cart",
  warning_dist:
    "Please finish your purchase before adding items from another distributor",
  clear_date: "Clear Date",
  adults: "Adults",
  seniors: "Seniors",
  children: "Children",
  duration: "Duration",
  adults_count: "Adults: ",
  seniors_count: "Seniors: ",
  children_count: "Children: ",
  check_in_date: "Check In: {{ date }}",
  check_out_date: "Check Out: {{ date }}",
  extra_options: "Extra Options",
  minutes: "minutes",
  hours: "hours",
  shuzenji: "Shuzenji area map",
  trip_history: "Trip History",
  instructions: "Instructions/FAQ",
  change_area: "Change area",
  terms: 'Terms of Use',
  app_provider: 'App provider',
  login_details: 'Login details',
  book_now_oto: 'Book Now',
  desktop_desc: `This app uses GPS to provide localized audio content. <br />
  Please access and enjoy the event on your smartphone or other
  mobile device by clicking on the QR link below.`,
  shuzenji_area: 'Shuzenji area map',
  about_oto: 'About Otomotabi',
};
